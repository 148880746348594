import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	lockersMaintenanceLogs: [],
	loading: false,
	error: false,
	message: ''
};

const logsSlice = createSlice({
	name: 'Logs',
	initialState,
	reducers: {
		loadMaintenanceLockersLogs(state) {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadMaintenanceLockersLogsSuccess(state, action) {
			state.lockersMaintenanceLogs = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadMaintenanceLockersLogsError(state) {
			state.loading = false;
			state.error = true;
			state.message = 'There was an error loading the lockers maintenance logs.';
		},
		upsertMaintenanceLockerLogs: (state) => {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		upsertMaintenanceLockerLogsSuccess: (state, action) => {
			const existingIndex = state.lockersMaintenanceLogs.findIndex(
				(log) => log.maintenanceID === action.payload.maintenanceID
			);

			if (existingIndex !== -1) {
				state.lockersMaintenanceLogs[existingIndex] = action.payload;
			} else {
				state.lockersMaintenanceLogs = [action.payload, ...state.lockersMaintenanceLogs];
			}
			state.loading = false;
			state.message = '';
		},
		upsertMaintenanceLockerLogsError: (state) => {
			state.loading = false;
			state.error = true;
			state.message = "There was an error updating the locker's maintenance.";
		},
		deleteMaintenanceLockerLogs: (state) => {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		deleteMaintenanceLockerLogsSuccess: (state, action) => {
			state.loading = false;
			state.lockersMaintenanceLogs = state.lockersMaintenanceLogs.filter(
				(locker) => locker.maintenanceID !== action.payload
			);
			state.message = '';
			state.error = false;
		},
		deleteMaintenanceLockerLogsError: (state) => {
			state.loading = false;
			state.error = true;
			state.message = "There was an error deleting the locker's maintenance.";
		}
	}
});

export const logsActions = logsSlice.actions;

export default logsSlice.reducer;
