import superFetch from 'library/helpers/superFetch';
import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import React, { useState } from 'react';
import { Notification } from 'zComponents/atoms';
import { Button, Modal, Tooltip } from 'zui/Antd';

const RemoteLockButton = ({ visible = false, setVisible, children, data }) => {
	// basic information for this modal to be used in the form.
	const { deviceID, name } = data;

	const [unlockLoading, setUnlockLoading] = useState(false);

	const handleCancel = () => {
		setVisible(false);
		setUnlockLoading(false);
	};

	const handleUnlock = async () => {
		if (!deviceID) return;
		setUnlockLoading(true);

		const body = {
			actionID: 2,
			deviceIDs: [deviceID],
			platform: 'Dashboard',
			method: 'Remote'
		};
		try {
			const res = await superFetch.post(`/devices/action`, body);
			if (res.statusCode === 200) {
				Notification('success', `${name} has been locked successfully.`);
				handleCancel();
			} else {
				Notification('error', res.data[0].errmsg || res.data[0].msg);
				setUnlockLoading(false);
			}
		} catch (error) {
			Notification('error', 'Something went wrong. Please try again later.');
			handleCancel();
		}
	};

	// Permission check for remote lock button.
	const isPermittedRemoteLock = useManagePermittedRoles('aCRemoteUnlock', {
		orgID: [data.orgID],
		siteID: [],
		floorID: data?.location?.length > 0 ? data?.location.map((location) => location.floorID) : []
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedRemoteLock
						? "You don't have permission"
						: 'Remotely Lock your Device from Anywhere with Internet'
				}
			>
				{children ? (
					children
				) : (
					<>
						<Button
							disabled={!isPermittedRemoteLock}
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
						>
							Remote Lock
						</Button>
					</>
				)}
			</Tooltip>
			<Modal
				title='Remote Lock Door'
				visible={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						onClick={() => handleUnlock()}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={unlockLoading}
					>
						Lock
					</Button>
				]}
			>
				<p style={{ textAlign: 'center' }}>
					Are you sure you want to Lock <strong>{name ? name : ''}</strong>?
					<span style={{ fontSize: '12px', color: 'red' }}>
						**The door will be locked even if it's in a passage mode.
					</span>
				</p>
			</Modal>
		</div>
	);
};

export default RemoteLockButton;
