import * as React from 'react';
const IconSad = ({ fill, ...props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={27} height={28} fill='none' {...props}>
		<path
			fill={fill || '#5F6368'}
			d='M17.388 12.878c.468 0 .865-.164 1.192-.49.328-.328.491-.725.491-1.193 0-.467-.164-.864-.49-1.191a1.623 1.623 0 0 0-1.193-.491c-.467 0-.864.163-1.192.49-.327.328-.49.725-.49 1.192 0 .468.163.865.49 1.192.328.327.725.491 1.192.491Zm-7.852 0c.467 0 .864-.164 1.192-.49.327-.328.49-.725.49-1.193 0-.467-.163-.864-.49-1.191a1.623 1.623 0 0 0-1.192-.491c-.468 0-.865.163-1.192.49-.327.328-.49.725-.49 1.192 0 .468.163.865.49 1.192.327.327.724.491 1.192.491Zm3.926 2.805A5.94 5.94 0 0 0 10 16.763a6.153 6.153 0 0 0-2.258 2.846h1.85a4.6 4.6 0 0 1 1.642-1.64 4.314 4.314 0 0 1 2.23-.604c.803 0 1.546.201 2.229.603a4.6 4.6 0 0 1 1.64 1.64h1.851a6.152 6.152 0 0 0-2.257-2.846 5.94 5.94 0 0 0-3.464-1.08Zm0 9.535c-1.552 0-3.01-.295-4.375-.884a11.328 11.328 0 0 1-3.562-2.397 11.33 11.33 0 0 1-2.397-3.562A10.924 10.924 0 0 1 2.244 14c0-1.552.295-3.01.884-4.375a11.329 11.329 0 0 1 2.397-3.562 11.329 11.329 0 0 1 3.562-2.398 10.924 10.924 0 0 1 4.375-.883c1.552 0 3.01.294 4.375.883A11.33 11.33 0 0 1 21.4 6.063a11.328 11.328 0 0 1 2.398 3.562c.589 1.365.883 2.823.883 4.375s-.294 3.01-.883 4.375a11.328 11.328 0 0 1-2.398 3.562 11.328 11.328 0 0 1-3.562 2.397 10.924 10.924 0 0 1-4.375.884Zm0-2.244c2.505 0 4.628-.87 6.366-2.608 1.74-1.739 2.608-3.86 2.608-6.366 0-2.505-.869-4.628-2.608-6.366-1.738-1.74-3.86-2.608-6.366-2.608-2.505 0-4.627.869-6.366 2.608C5.357 9.372 4.488 11.494 4.488 14c0 2.505.87 4.627 2.608 6.366 1.739 1.739 3.86 2.608 6.366 2.608Z'
		/>
	</svg>
);
export default IconSad;
