import * as React from 'react';
const MaintenanceCard = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={26} height={27} fill='none' {...props}>
		<rect width={26} height={26} y={0.5} fill='#059641' rx={13} />
		<path
			fill='#fff'
			d='M19.727 8.04H6.523a.507.507 0 0 0-.507.507v2.539h14.218v-2.54a.507.507 0 0 0-.507-.507ZM6.016 18.702c0 .281.227.508.507.508h13.204c.28 0 .507-.227.507-.508v-6.22H6.016v6.22Zm9.188-2.92c0-.07.057-.127.127-.127h2.618c.07 0 .127.057.127.127v1.143c0 .07-.057.127-.127.127h-2.618a.127.127 0 0 1-.127-.127v-1.143Z'
		/>
	</svg>
);
export default MaintenanceCard;
