import { UserOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import dayjs from 'dayjs';
import { removeEmptyOrNull } from 'library/helpers/utility';
import fieldsValidator from 'library/helpers/validators/fieldsValidator';
import lodash from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { peopleActions } from 'reduxStore/people/slice';
import { sitesActions } from 'reduxStore/sites/slice';
import { PersonProfileImage } from 'zComponents/atoms';
import { Button, DatePicker, Flex, Form, Input, Modal, Select, Typography } from 'zui/Antd';

const { Title } = Typography;

const EditPeopleModal = ({ children, visible, setVisible, data }) => {
	// Hooks
	const {
		token: { paddingXL, marginXXL, fontWeightStrong }
	} = theme.useToken();
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	// Redux
	const { data: sites } = useSelector((state) => state.Sites);

	const { loadingUpdateProfile } = useSelector((state) => state.People);

	// Local State
	const [formChanged, setFormChanged] = useState(false);
	const uploadRef = useRef(null);

	useEffect(() => {
		if (visible) {
			dispatch(sitesActions.loadSites());
		}
	}, [dispatch, visible]);

	// Set initial values to the form when modal visibles
	useEffect(() => {
		if (data) {
			form.setFieldsValue({
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				dOB: data.dOB ? dayjs(data.dOB) : null,
				siteID: data.siteID || '',
				sex: data.sex || '',
				photoUrl: data.photoUrl
			});
			setFormChanged(false); // Reset formChanged when new data is set
		}
	}, [data, form]);

	// Track changes to form values
	const handleValuesChange = (_, allValues) => {
		const originalValues = {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			dateOfBirth: data.dOB ? dayjs(data.dOB) : null,
			site: data.siteID || '',
			sex: data.sex || '',
			photoUrl: data.photoUrl
		};

		// Use lodash to compare allValues with originalValues
		setFormChanged(!lodash.isEqual(allValues, originalValues));
	};

	const handleCancel = () => {
		setVisible(false);
		dispatch(peopleActions.cleanupError());
		form.resetFields();
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Button
					onClick={() => setVisible(true)}
					icon={<UserOutlined />}
					iconPosition='start'
					style={{
						width: '100%',
						justifyContent: 'start'
					}}
				>
					Edit Profile
				</Button>
			)}
			<Modal
				visible={visible}
				onCancel={handleCancel}
				centered
				closeIcon={false}
				width={1235}
				destroyOnClose={true}
				styles={{
					content: {
						padding: `${48}px ${paddingXL}px `
					}
				}}
				footer={null}
			>
				<Flex vertical gap={48}>
					<Title level={3} style={{ margin: 0 }}>
						<UserOutlined /> Edit Profile
					</Title>

					<Form
						form={form}
						layout='vertical'
						onValuesChange={handleValuesChange} // Detect changes to enable the Save button
						onFinish={(values) => {
							dispatch(
								peopleActions.updatePeople({
									personID: data.personID,
									data: removeEmptyOrNull(values)
								})
							);
						}}
					>
						<Flex justify='space-between' gap={32}>
							<Flex flex={1} justify='center'>
								<Form.Item name='photoUrl'>
									<PersonProfileImage
										size={167}
										entityID={data?.personID}
										photoUrl={data?.photoUrl}
										entityName={'People'}
										uploadRef={uploadRef}
										shape={'circle'}
									/>
								</Form.Item>
							</Flex>
							<Flex vertical gap={16} flex={6}>
								<Title level={4} style={{ margin: 0 }}>
									User Details
								</Title>
								{/* First Name and Last Name */}
								<Flex justify='space-between' gap={27}>
									<Form.Item
										label='First Name'
										name='firstName'
										required={false}
										rules={fieldsValidator.Name}
										style={{ width: '100%', margin: 0, fontWeight: fontWeightStrong }}
									>
										<Input placeholder='Name' />
									</Form.Item>
									<Form.Item
										label='Last Name'
										name='lastName'
										required={false}
										rules={fieldsValidator.Name}
										style={{ width: '100%', margin: 0, fontWeight: fontWeightStrong }}
									>
										<Input placeholder='Last Name' />
									</Form.Item>
								</Flex>

								{/* Email and Date of Birth */}
								<Flex justify='space-between' gap={27}>
									<Form.Item
										label='Email'
										name='email'
										style={{ width: '100%', margin: 0, fontWeight: fontWeightStrong }}
									>
										<Input placeholder='user@email.com' disabled />
									</Form.Item>
									<Form.Item
										label='Date of Birth'
										name='dOB'
										style={{ width: '100%', margin: 0, fontWeight: fontWeightStrong }}
									>
										<DatePicker style={{ width: '100%' }} format={'DD/MM/YYYY'} maxDate={dayjs()} />
									</Form.Item>
								</Flex>

								{/* Site and Sex */}
								<Flex justify='space-between' gap={27}>
									<Form.Item
										label='Site'
										name='siteID'
										style={{ width: '100%', margin: 0, fontWeight: fontWeightStrong }}
									>
										<Select placeholder='Site'>
											{sites.map((site) => (
												<Select.Option key={site.siteID} value={site.siteID}>
													{site.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label='Sex'
										name='sex'
										style={{ width: '100%', margin: 0, fontWeight: fontWeightStrong }}
									>
										<Select placeholder='Select Sex'>
											<Select.Option value='Male'>Male</Select.Option>
											<Select.Option value='Female'>Female</Select.Option>
											<Select.Option value='Other'>Unlisted</Select.Option>
										</Select>
									</Form.Item>
								</Flex>
							</Flex>
						</Flex>
						<Flex align='center' justify='end' style={{ marginTop: marginXXL }} gap={20}>
							<Button onClick={handleCancel} type='default'>
								Cancel
							</Button>
							<Button
								size='middle'
								type='primary'
								htmlType='submit'
								disabled={!formChanged}
								loading={loadingUpdateProfile}
							>
								Save Changes
							</Button>
						</Flex>
					</Form>
				</Flex>
			</Modal>
		</>
	);
};

export default EditPeopleModal;
