import React from 'react';

const DeletedFilled = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' viewBox='0 0 88 88' fill='none'>
			<path
				d='M22.5557 29.8889H18.889V77.5555C18.889 79.5005 19.6616 81.3657 21.0369 82.741C22.4121 84.1163 24.2774 84.8889 26.2223 84.8889H62.889C64.8339 84.8889 66.6992 84.1163 68.0744 82.741C69.4497 81.3657 70.2223 79.5005 70.2223 77.5555V29.8889H22.5557ZM37.2223 73.8889H29.889V40.8889H37.2223V73.8889ZM59.2223 73.8889H51.889V40.8889H59.2223V73.8889ZM61.4883 18.8889L55.5557 11.5555H33.5557L27.623 18.8889H11.5557V26.2222H77.5557V18.8889H61.4883Z'
				fill='black'
				fill-opacity='0.88'
			/>
		</svg>
	);
};

export default DeletedFilled;
