// import jwtConfig from 'config/jwt.config';
import siteConfig from 'config/site.config';
import { jwtDecode } from 'jwt-decode';
import { getCurrentUser } from 'library/firebase/fb.auth.utils';
import { store } from 'reduxStore/store';
import { Notification } from 'zComponents/atoms';

export const getOrgAlias = () => {
	return 'DF'; // WIP - add controlled domain alias which by default send back the controlled response to so show proper errors.
};
export const getOrgID = () => {
	var state = store.getState() || undefined;

	if (state.Auth?.selectedProfile?.orgID) {
		return state.Auth.selectedProfile.orgID || undefined;
	} else {
		return undefined; //? Sends undefined to the backend so the request is thrown and the error is handled.
	}
};

export function applicationServerURLGenerator(orgID) {
	if (process.env.NODE_ENV === 'development') {
		return `http://${window.location.hostname}:8080/v3/${orgID || getOrgID()}`;
	} else {
		return `https://${getOrgAlias()}.${siteConfig.applicationServerBaseDomain}/v3/${orgID || getOrgID()}`;
	}
}

const getToken = async () => {
	var token = (await getCurrentUser().then(async (user) => await user.getIdToken())) || undefined;
	try {
		const profile = jwtDecode(token);
		const expiredAt = profile.expiredAt || profile.exp * 1000;
		if (expiredAt < new Date().getTime() - 10000) {
			await getCurrentUser().then(async (user) => {
				let newToken = await user.getIdToken(true);
				localStorage.setItem('id_token', newToken);
				token = newToken;
			});
		}
		return token;
	} catch (e) {
		Notification('error', 'There is an Error! Please reload the page.');
		console.log(e);
	}
};

const customHeader = async () => {
	return {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + (await getToken()) || undefined
	};
};

// JS Fetch does not allow Query Params with the PATCH req. Please make sure that the query params are null when using the patch method.
const base = async (method, slug, body = {}, queryParam = {}, overrideOrgID) => {
	if (method === 'patch' && Object.keys(queryParam).length !== 0) {
		return { errors: 'Query parameters are not allowed with patch request' };
	} else {
		try {
			const response = await fetch(
				`${applicationServerURLGenerator(overrideOrgID)}${slug}?${Object.keys(queryParam)
					.map((key) => key + '=' + encodeURIComponent(queryParam[key]))
					.join('&')}`,
				{
					method: method.toUpperCase(),
					headers: await customHeader(),
					body: method !== 'get' ? JSON.stringify(body) : null
				}
			);
			const res = await response.json();
			return res;
		} catch (error) {
			return { errors: 'Server Error', error };
		}
	}
};

const SuperFetch = {};
['get', 'post', 'put', 'delete', 'patch'].forEach((method) => {
	SuperFetch[method] = base.bind(null, method);
});

export default SuperFetch;
