import SuperFetch from 'library/helpers/superFetch';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { peopleActions } from './slice';
import { resetPassword } from 'library/firebase/fb.auth.utils';
import { authActions } from 'reduxStore/auth/slice';

export function* getPeople(action) {
	try {
		const { payload } = action;
		const lockerPeople = yield call(() => SuperFetch.get('/lockers/people', {}, payload));

		if (lockerPeople.statusCode === 200) {
			yield put(peopleActions.loadPeopleSuccess(lockerPeople));
		} else {
			yield put(peopleActions.loadPeopleError());
		}
	} catch (error) {
		yield put(peopleActions.loadPeopleError());
	}
}

export function* getMoreLockerPeople(action) {
	try {
		const { payload } = action;

		const lockerPeople = yield call(() => SuperFetch.get('/lockers/people', {}, payload));

		if (lockerPeople.statusCode === 200) {
			yield put(peopleActions.loadMoreLockerPeopleSuccess(lockerPeople));
		} else {
			yield put(peopleActions.loadMoreLockerPeopleError());
		}
	} catch (error) {
		yield put(peopleActions.loadMoreLockerPeopleError());
	}
}

export function* getFilteredLockerPeople(action) {
	try {
		const { payload } = action;
		const filteredLockerPeople = yield call(() => SuperFetch.get('/lockers/people', {}, payload));

		if (filteredLockerPeople.statusCode === 200) {
			// Update the state with the filtered data
			yield put(peopleActions.loadFilteredLockerPeopleSuccess(filteredLockerPeople));
		} else {
			yield put(peopleActions.loadFilteredLockerPeopleError());
		}
	} catch (error) {
		yield put(peopleActions.loadFilteredLockerPeopleError());
	}
}

export function* updateProfile(action) {
	const { personID, data } = action.payload;

	try {
		// Remove email from the data to prevent it from being updated
		delete data.email;

		const updateProfile = yield call(() => SuperFetch.patch(`/people/${personID}`, data));

		const { personID: userID } = yield select((state) => state.Auth.selectedProfile);

		if (updateProfile.statusCode === 200) {
			// Update the state to reflect the change
			yield put(peopleActions.updatePeopleSuccess({ personID, data }));

			if (personID === userID) {
				yield put(authActions.updateUserProfile(data));
			}
			//Close Modal
		} else {
			yield put(peopleActions.updatePeopleError());
		}
	} catch (error) {
		yield put(peopleActions.updatePeopleError());
	}
}

export function* updateProfilePhoto(action) {
	try {
		yield put(peopleActions.updatePeoplePhotoSuccess(action.payload));
	} catch {
		yield put(peopleActions.updatePeoplePhotoError());
	}
}

export function* enableProfile(action) {
	const { personID } = action.payload;

	try {
		const enableProfile = yield call(() => SuperFetch.post(`/people/enablePerson/${personID}`));

		if (enableProfile.statusCode === 200) {
			// Update the state to reflect the change
			yield put(peopleActions.enablePeopleSuccess(personID));
		} else {
			yield put(peopleActions.enablePeopleError());
		}
	} catch (error) {
		yield put(peopleActions.enablePeopleError());
	}
}

export function* resetProfilePassword(action) {
	const email = action.payload;

	try {
		const res = yield call(() => resetPassword(email));

		if (!res) {
			yield put(peopleActions.resetPeoplePasswordSuccess());
		} else {
			yield put(peopleActions.resetPeoplePasswordError());
		}
	} catch (error) {
		yield put(peopleActions.resetPeoplePasswordError());
	}
}

export function* disableProfile(action) {
	const { personID } = action.payload;

	try {
		const disableProfile = yield call(() => SuperFetch.post(`/people/disablePerson/${personID}`));

		if (disableProfile.statusCode === 200) {
			// Update the state to reflect the change
			yield put(peopleActions.disablePeopleSuccess(personID));
		} else {
			peopleActions.disablePeopleError();
		}
	} catch (error) {
		yield put(peopleActions.disablePeopleError());
	}
}

export function* deleteProfile(action) {
	const { personID } = action.payload;

	try {
		const deleteProfile = yield call(() => SuperFetch.delete(`/people/${personID}`));

		if (deleteProfile.statusCode === 200) {
			yield put(peopleActions.deletePeopleSuccess(personID));

			// Get the updated pagination state after deletion
			const { page, size, total } = yield select((state) => state.People.pagination);
			const currentPageItems = yield select((state) => state.People.data.length);

			// Check if a refetch is needed to maintain a full page
			if (currentPageItems < size && total > size * (page - 1)) {
				yield put(peopleActions.loadMoreLockerPeople({ page, size }));
			}
		} else {
			yield put(peopleActions.deletePeopleError());
		}
	} catch (error) {
		yield put(peopleActions.deletePeopleError());
	}
}

export default function* rootSaga() {
	yield takeEvery(peopleActions.loadPeople, getPeople);
	yield takeEvery(peopleActions.loadMoreLockerPeople, getMoreLockerPeople);
	yield takeEvery(peopleActions.loadFilteredLockerPeople, getFilteredLockerPeople);
	yield takeEvery(peopleActions.resetPeoplePassword, resetProfilePassword);
	yield takeEvery(peopleActions.updatePeople, updateProfile);
	yield takeEvery(peopleActions.updatePeoplePhoto, updateProfilePhoto);
	yield takeEvery(peopleActions.deletePeople, deleteProfile);
	yield takeEvery(peopleActions.enablePeople, enableProfile);
	yield takeEvery(peopleActions.disablePeople, disableProfile);
}
