import SuperFetch from 'library/helpers/superFetch';
import { removeEmptyOrNull } from 'library/helpers/utility';
import fieldsValidator from 'library/helpers/validators/fieldsValidator';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sitesActions } from 'reduxStore/sites/slice';
import { Notification } from 'zComponents/atoms';
import { Button, Col, Flex, Form, Input, Modal, Row, Select } from 'zui/Antd';

const AddSiteButton = () => {
	const { addressTypes } = useSelector((state) => state.Types);

	const [confirmLoading, setConfirmLoading] = useState(false);
	const [visible, setVisible] = useState(false);

	const [stepForm] = Form.useForm();
	const dispatch = useDispatch();

	const showModal = () => {
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		stepForm.resetFields();
	};

	const onFinish = async () => {
		setConfirmLoading(true);
		// Collect all fields + remove empty/null
		const formData = removeEmptyOrNull(stepForm.getFieldsValue(true));

		try {
			const res = await SuperFetch.post('/sites', formData);
			if (res.statusCode === 201) {
				dispatch(sitesActions.loadSites());
				setConfirmLoading(false);
				handleCancel();
				Notification('success', 'Site added successfully');
			} else {
				setConfirmLoading(false);
				Notification('error', 'Failed to add site');
			}
		} catch (error) {
			setConfirmLoading(false);
			Notification('error', 'Failed to add site');
		}
	};

	const reset = () => {
		stepForm.setFieldsValue({ name: '', address: [{ country: 'Australia' }] });
		stepForm.resetFields(['name', 'address']);
	};

	return (
		<div>
			<Button type='primary' onClick={showModal}>
				Add New Site
			</Button>
			<Modal
				title='Add New Site'
				open={visible}
				onCancel={handleCancel}
				width={960}
				maskClosable={false}
				destroyOnClose={true}
				footer={null}
			>
				<div style={{ margin: '0 100px' }}>
					<Form
						name='addSiteForm'
						form={stepForm}
						labelCol={{ span: 6 }}
						wrapperCol={{ span: 18 }}
						onFinish={onFinish}
						autoComplete='off'
						// Provide default values to ensure these fields exist
						initialValues={{
							address: [
								{
									country: 'Australia' // Pre-fill country
								}
							]
						}}
					>
						<Row gutter={0} justify='space-around'>
							<Col lg={24} md={24} sm={24} xs={24}>
								<Flex gap='middle' vertical>
									<Form.Item label='Site Name' name='name' rules={fieldsValidator.Name}>
										<Input />
									</Form.Item>

									<div>
										{/* Address Type */}
										<Form.Item
											name={['address', 0, 'addressTypeID']}
											label='Address Type'
											rules={[{ required: true, message: 'Address Type is required' }]}
										>
											<Select
												style={{ width: 200 }}
												placeholder='Select Address Type'
												options={addressTypes.map((type) => ({
													value: type.addressTypeID,
													label: type.name
												}))}
											/>
										</Form.Item>

										{/* Address Line 1 */}
										<Form.Item
											label='Address Line 1'
											name={['address', 0, 'addressLine1']}
											rules={fieldsValidator.AddressLine1}
										>
											<Input />
										</Form.Item>

										{/* Address Line 2 */}
										<Form.Item
											label='Address Line 2'
											name={['address', 0, 'addressLine2']}
											rules={fieldsValidator.AddressLine2}
										>
											<Input />
										</Form.Item>

										<Row>
											<Col span={12}>
												<Form.Item
													label='Suburb'
													name={['address', 0, 'suburb']}
													labelCol={{ span: 12 }}
													wrapperCol={{ span: 12 }}
													rules={fieldsValidator.Suburb}
												>
													<Input />
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													label='State'
													name={['address', 0, 'state']}
													labelCol={{ span: 10 }}
													wrapperCol={{ span: 12 }}
													// If you require a state, add a rule here
													// rules={[{ required: true, message: 'State is required' }]}
												>
													<Select
														options={[
															'NSW',
															'QLD',
															'SA',
															'TAS',
															'VIC',
															'WA',
															'ACT',
															'NT'
														].map((state) => ({
															value: state,
															label: state
														}))}
													/>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													label='Post Code'
													name={['address', 0, 'postCode']}
													labelCol={{ span: 12 }}
													wrapperCol={{ span: 12 }}
													rules={fieldsValidator.PostCode}
												>
													<Input />
												</Form.Item>
											</Col>
										</Row>

										{/* Country */}
										<Form.Item
											label='Country'
											name={['address', 0, 'country']}
											wrapperCol={{ span: 6 }}
											rules={[{ required: true, message: 'Country is required' }]}
										>
											<Select>
												<Select.Option value='Australia'>Australia</Select.Option>
												{/* Add more countries if needed */}
											</Select>
										</Form.Item>
									</div>
								</Flex>
							</Col>
							<Flex justify='flex-end' gap='middle' align='flex-end' style={{ width: '100%' }}>
								<Button key='reset' onClick={reset}>
									Reset
								</Button>
								<Button loading={confirmLoading} key='submit' htmlType='submit' type='primary'>
									Submit
								</Button>
							</Flex>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
};

export default AddSiteButton;
