import { Card } from 'antd';
import styled from 'styled-components';

export default styled(Card)`
	// Add your styles here
`;

const BackgroundCard = styled(Card)`
	// Add your styles here

	margin: 0;
	height: auto;
	width: 100%;

	.ant-card-body {
		padding: 10px 18px;
	}
`;
export { BackgroundCard };
