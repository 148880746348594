import * as React from 'react';

const AddOutlined = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='currentColor' {...props}>
		<g>
			<path d='M13.333 1.833h-8C4.598 1.833 4 2.431 4 3.167v8c0 .735.598 1.333 1.333 1.333h8c.736 0 1.334-.598 1.334-1.333v-8c0-.736-.598-1.334-1.334-1.334Zm-8 9.334v-8h8l.002 8H5.333Z' />
			<path d='M2.667 5.833H1.334v8c0 .736.597 1.334 1.333 1.334h8v-1.334h-8v-8ZM10 4.5H8.667v2h-2v1.333h2v2H10v-2h2V6.5h-2v-2Z' />
		</g>
	</svg>
);

export default AddOutlined;
