import { UserOutlined } from '@ant-design/icons';
import { getImageUrlFromStorage } from 'library/helpers/getImageUrlFromStorage';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector } from 'react-redux';
import { Avatar } from 'zui/Antd';
import { Loader } from '..';
import ProfilePicUpload from '../UploadImageFirebase';
import ProfileImageWrapper from './styles';

/**
 * @description
 * @param {*}  entityObject  Entity object e.g. People or Team
 * @param {*}  entityName  Entity object e.g. People or Team
 * @param {*}  size Size of the image/avatar
 * @return {*}
 */

const PersonProfileImage = ({ entityID, photoUrl, size, entityName, shape, cursor, edit, uploadRef }) => {
	const { isLoadingPeoplePhoto } = useSelector((state) => state.People);
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchImage = async () => {
			setLoading(isLoadingPeoplePhoto || true);

			if (photoUrl) {
				try {
					const url = await getImageUrlFromStorage(photoUrl);
					setImage(url);
				} catch (error) {
					setImage(null);
					console.error('Error fetching image:', error);
				}
			} else {
				setImage(null);
			}
			setLoading(false); // End loading
		};

		fetchImage();
	}, [photoUrl, isLoadingPeoplePhoto]);

	return (
		<ProfileImageWrapper size={size || 150} shape={shape || 'square'} cursor={cursor ? 'pointer' : 'default'}>
			{edit !== false ? (
				<ProfilePicUpload
					cropperOptions={{ grid: true, rotate: true }}
					entityName={entityName}
					entityID={entityID}
					uploadType={'profilePicture'}
					oldData={{
						photoUrl
					}}
					uploadRef={uploadRef}
				>
					<div className='contentContainer'>
						<div className='editIconContainer'>
							<p className='editIconContent'>
								{image ? 'Update' : 'Upload'} <br />
								Picture
							</p>
						</div>
						{loading ? (
							<div className='imageLazy'>
								<Avatar size={size || 150} icon={<Loader style={{ position: 'relative' }} />} />
							</div>
						) : image ? (
							<div className='imageLazy'>
								<LazyLoadImage src={image} effect='blur' loading='lazy' />
							</div>
						) : (
							<Avatar size={size || 150} icon={<UserOutlined />} />
						)}
					</div>
				</ProfilePicUpload>
			) : (
				<span>
					{loading ? (
						<div className='imageLazy'>
							<Avatar size={size || 150} icon={<Loader style={{ position: 'relative' }} />} />
						</div>
					) : image ? (
						<div className='imageLazy'>
							<LazyLoadImage src={image} effect='blur' loading='lazy' />
						</div>
					) : (
						<Avatar size={size || 150} icon={<UserOutlined size={150} />} />
					)}
				</span>
			)}
		</ProfileImageWrapper>
	);
};

export default PersonProfileImage;
