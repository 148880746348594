import { useState } from 'react';
import { Button, Input, List, Tag, Typography, Modal, Flex, Avatar, Notification } from 'zui/Antd';
import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import { useSelector } from 'react-redux';
import SuperFetch from 'library/helpers/superFetch';
import { Loader } from 'zComponents/atoms';

const { Title, Text } = Typography;

const InvitePeopleModal = ({ children, visible, setVisible }) => {
	// Hooks
	const {
		token: {
			fontSizeSM,
			fontWeightStrong,
			colorBorderSecondary,
			paddingXS,
			paddingLG,
			paddingMD,
			colorIcon,
			colorText,
			marginLG,
			fontSize
		}
	} = theme.useToken();

	// Redux state
	//TODO: We need to change this to the correct slice
	const { peopleMetrics: data } = useSelector((state) => state.Metrics);

	// Local State
	const [emailInput, setEmailInput] = useState('');
	const [selectedEmails, setSelectedEmails] = useState([]);
	const [loading, setLoading] = useState(false);

	// Email Validation Function
	const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

	// Handle adding emails to the list
	const handleAddEmails = () => {
		const emails = emailInput.split(',').map((email) => email.trim());
		const validEmails = [...new Set(emails.filter((email) => isValidEmail(email)))]; // Remove duplicates and keep only valid emails

		// Map each valid email to either an existing user status or a new pending invite
		const newEmails = validEmails
			.filter((email) => !selectedEmails.some((user) => user.email === email)) // Avoid duplicates in selectedEmails
			.map((email) => {
				// Check if the email exists in `data`
				const existingUser = data.find((person) => person.email === email);
				if (existingUser) {
					// Use status from `data` if found
					return {
						email: existingUser.email,
						status: existingUser.isActive && !existingUser.isNewUser ? 'accepted' : 'pending'
					};
				} else {
					// Otherwise, default to a new pending invite
					return { email, status: 'pending' };
				}
			});

		setSelectedEmails([...selectedEmails, ...newEmails]);
		setEmailInput('');
	};

	// Handle sending an invite for a specific email
	const handleSendInvite = async (email) => {
		setSelectedEmails((prevEmails) =>
			prevEmails.map((e) => (e.email === email ? { ...e, status: 'inviting' } : e))
		);
		try {
			const res = await SuperFetch.post('/people', { email });
			if (res.statusCode === 201) {
				setSelectedEmails((prevEmails) =>
					prevEmails.map((e) => (e.email === email ? { ...e, status: 'invited' } : e))
				);
				Notification.success({ message: `Invitation sent` });
			} else {
				throw new Error(res.error || 'Failed to invite user');
			}
		} catch (error) {
			setSelectedEmails((prevEmails) =>
				prevEmails.map((e) => (e.email === email ? { ...e, status: 'pending' } : e))
			);
			Notification.error({ message: `Failed to invite ${email}`, description: error.message });
		}
	};

	// Handle sending invites for all pending emails
	const handleSendAllInvites = async () => {
		if (selectedEmails.find((e) => e.status === 'pending')) {
			setLoading(true);
			for (const user of selectedEmails) {
				if (user.status === 'pending') {
					await handleSendInvite(user.email);
				}
			}
			Notification.success({ message: `Invitations sent` });
			setLoading(false);
		} else {
			Notification.error({ message: `There is not any invitation to send.` });
		}
	};

	// Handle clearing the list of emails
	const handleClearList = () => {
		setSelectedEmails([]);
	};

	// Handle cancel button
	const handleCancel = () => {
		setVisible(false);
		handleClearList();
	};

	const navigateToSupport = () => {
		window.open(
			'https://support.zezamii.com/hc/en-us/articles/11041741330831-How-to-Enrol-Users-to-the-Zezamii-Lockers-Dashboard',
			'_blank'
		);
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Button
					type='primary'
					onClick={() => {
						setVisible(true);
					}}
				>
					Invite users
				</Button>
			)}
			<Modal
				open={visible}
				onCancel={() => handleCancel()}
				centered
				footer={null}
				width={581}
				closeIcon={false}
				styles={{
					content: {
						padding: `${paddingLG}px ${paddingMD}px `
					}
				}}
			>
				<Flex vertical gap={32}>
					{/* Title */}
					<Flex align='start' justify='space-between'>
						<Flex vertical>
							<Title level={4} style={{ margin: 0 }}>
								Invite Users to Workspace
							</Title>
							<Text style={{ fontSize: fontSizeSM }}>
								Sending users an invite gives them access to the Zezamii Lockers sign-up link.
							</Text>
						</Flex>
						<InfoCircleOutlined
							style={{ fontSize: 22, color: colorIcon }}
							onClick={() => navigateToSupport()}
						/>
					</Flex>

					{/* Email Input */}
					<Flex vertical gap={8}>
						<Title level={3} style={{ margin: 0, fontSize: fontSize }}>
							Invite Email Addresses
						</Title>
						<Input
							value={emailInput}
							onChange={(e) => setEmailInput(e.target.value)}
							placeholder='user@workspace.com, user2@workspace.com'
							prefix={<MailOutlined style={{ color: colorIcon }} />}
							onPressEnter={handleAddEmails}
							onBlur={handleAddEmails}
							styles={{
								input: {
									paddingLeft: paddingXS
								}
							}}
						/>
					</Flex>

					{/* Selected Emails */}
					<Flex vertical gap={20}>
						<Flex align='center' justify='space-between'>
							<Title level={3} style={{ margin: 0, fontSize: fontSize }}>
								Selected Addresses
							</Title>
							<Button
								type='text'
								size='small'
								onClick={handleClearList}
								style={{
									textDecoration: 'underline'
								}}
							>
								Clear List
							</Button>
						</Flex>

						{/* Selected Emails List */}
						<List
							style={{
								paddingBottom: 27,
								borderBottom: `1px solid ${colorBorderSecondary}`,
								minHeight: 182
							}}
							dataSource={selectedEmails}
							renderItem={(user) => (
								<List.Item
									style={{
										border: 0,
										padding: `${paddingXS}px 0`
									}}
									extra={
										user.status === 'pending' ? (
											<Button
												onClick={() => handleSendInvite(user.email)}
												type='default'
												size='small'
												style={{
													fontSize: fontSizeSM,
													fontWeight: fontWeightStrong
												}}
											>
												Send Invite
											</Button>
										) : user.status === 'inviting' ? (
											<Tag color='green' bordered={false} icon={<Loader />} />
										) : user.status === 'accepted' ? (
											<Tag color='green' bordered={false} style={{ margin: 0, borderRadius: 18 }}>
												Invite Accepted
											</Tag>
										) : (
											<Tag color='green' bordered={false} style={{ margin: 0, borderRadius: 18 }}>
												Invite Sent
											</Tag>
										)
									}
								>
									<List.Item.Meta
										avatar={
											<Avatar
												size={30}
												style={{
													fontSize: 12,
													fontWeight: fontWeightStrong,
													color: colorText
												}}
											>
												{user.email?.charAt(0).toUpperCase()}
											</Avatar>
										}
										title={<Text style={{ fontWeight: 400 }}>{user.email}</Text>}
									/>
								</List.Item>
							)}
						/>
					</Flex>
				</Flex>
				<Flex align='center' justify='flex-end' gap={8} style={{ marginTop: marginLG }}>
					<Button type='primary' onClick={handleSendAllInvites} loading={loading}>
						Send Invites
					</Button>
					<Button onClick={() => handleCancel()}>Done</Button>
				</Flex>
			</Modal>
		</>
	);
};

export default InvitePeopleModal;
