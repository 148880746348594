import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Loader } from 'zComponents/atoms';
import ErrorBoundary from './ErrorBoundary';
import { DASHBOARD_ROUTES, PUBLIC_ROUTES } from './routes';

const FourZeroFour = lazy(() => import('publicContainer/404'));

const RestrictedRoute = () => {
	const { idToken: isAuthenticated } = useSelector((state) => state.Auth);

	return isAuthenticated ? <Outlet /> : <Navigate to={'signin'} replace />;
};

const Navigation = () => {
	return (
		<ErrorBoundary>
			<Suspense fallback={<Loader />}>
				<BrowserRouter>
					<Routes>
						<Route element={<Outlet />}>
							{PUBLIC_ROUTES.map((route, index) => (
								<Route path={route.path} key={index} element={<route.component />} />
							))}

							<Route element={<RestrictedRoute />}>
								{DASHBOARD_ROUTES.map(({ path, key, component: Component, index }) => (
									<Route index={index} path={`/${path}/*`} key={key} element={<Component />} />
								))}
							</Route>

							<Route path='*' element={<FourZeroFour />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</Suspense>
		</ErrorBoundary>
	);
};
export default Navigation;
