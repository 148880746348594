import * as React from "react"
const MaintenanceConfiguration = (props) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width= {26}
    height= {27}
    fill="none"
    {...props}
>
    <rect width= {26} height= {26} y= {0.5} fill="#059641" rx= {13} />
    <path
        fill="#fff"
        d="M19.417 7.827a.243.243 0 0 0-.381-.047l-3.27 3.268-1.288-1.288 3.27-3.27a.242.242 0 0 0-.047-.38 4.864 4.864 0 0 0-7.168 5.447l-5.559 5.559a.147.147 0 0 0 .006.209l3.22 3.22c.058.058.153.062.208.005l5.558-5.557a4.864 4.864 0 0 0 5.451-7.166Zm-1.72 4.938a3.496 3.496 0 0 1-4.071.638l-.167.167-.002-.002-5.199 5.2-1.502-1.502L11.12 12.9s0 .002.002.002l1.002-1.002a3.497 3.497 0 0 1 3.37-5.086l-2.522 2.52a.608.608 0 0 0 0 .859l2.362 2.362a.608.608 0 0 0 .86 0l2.52-2.52a3.5 3.5 0 0 1-1.017 2.73Z"
    />
</svg>
)
export default MaintenanceConfiguration