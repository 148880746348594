import * as React from 'react';

const YoutubeIcon = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} fill='none' {...props}>
		<g clipPath='url(#a)'>
			<path
				fill='#000'
				fillOpacity={0.45}
				d='M24 12.42c0-.059 0-.126-.003-.204-.002-.216-.008-.46-.013-.72a40.333 40.333 0 0 0-.118-2.194c-.08-.967-.198-1.773-.359-2.378a3.737 3.737 0 0 0-2.633-2.639c-.758-.203-2.242-.33-4.331-.407a125.98 125.98 0 0 0-3.12-.075c-.373-.005-.719-.008-1.03-.01h-.787a125.969 125.969 0 0 0-4.15.086c-2.088.08-3.575.205-4.33.406A3.733 3.733 0 0 0 .493 6.924c-.164.605-.279 1.411-.36 2.378a40.332 40.332 0 0 0-.117 2.194c-.008.26-.01.503-.013.72 0 .078-.003.145-.003.204v.15c0 .059 0 .126.003.204.002.217.008.46.013.72.021.748.059 1.492.118 2.194.08.967.198 1.773.359 2.379a3.733 3.733 0 0 0 2.633 2.638c.755.204 2.242.33 4.331.407.994.038 2.057.062 3.12.075.373.005.719.008 1.03.01h.787a125.541 125.541 0 0 0 4.15-.086c2.088-.08 3.575-.205 4.33-.406a3.728 3.728 0 0 0 2.633-2.638c.163-.606.279-1.412.36-2.38.058-.7.095-1.445.117-2.193.008-.26.01-.503.013-.72 0-.078.003-.145.003-.204v-.15Zm-1.929.14c0 .056 0 .117-.002.19-.003.209-.008.439-.014.688a36.306 36.306 0 0 1-.112 2.086c-.073.863-.174 1.57-.3 2.044a1.802 1.802 0 0 1-1.27 1.273c-.562.15-1.98.27-3.905.342a121.718 121.718 0 0 1-4.085.084h-.766l-1.013-.008a122.03 122.03 0 0 1-3.072-.076c-1.926-.075-3.345-.192-3.905-.342a1.807 1.807 0 0 1-1.27-1.273c-.126-.474-.228-1.181-.3-2.044a38.799 38.799 0 0 1-.112-2.086c-.008-.25-.011-.482-.014-.689l-.002-.19v-.128c0-.056 0-.118.002-.19.003-.21.008-.44.014-.689.018-.712.056-1.425.112-2.086.072-.863.174-1.57.3-2.044a1.802 1.802 0 0 1 1.27-1.272c.562-.15 1.98-.271 3.905-.343a121.672 121.672 0 0 1 4.085-.083h.766l1.013.008c1.047.013 2.094.037 3.072.075 1.926.075 3.345.193 3.905.343a1.807 1.807 0 0 1 1.27 1.272c.126.474.227 1.181.3 2.044.056.661.09 1.374.112 2.086.008.25.011.482.014.689l.002.19v.128ZM9.616 16.083l6.214-3.616-6.214-3.562v7.178Z'
			/>
		</g>
		<defs>
			<clipPath id='a'>
				<path fill='#fff' d='M0 .494h24v24H0z' />
			</clipPath>
		</defs>
	</svg>
);
export default YoutubeIcon;
