import * as React from 'react';
const IconSmile = ({ fill, ...props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={28} height={28} fill='none' {...props}>
		<path
			fill={fill || '#5F6368'}
			d='M17.773 12.878c.468 0 .865-.164 1.192-.49.327-.328.49-.725.49-1.193 0-.467-.163-.864-.49-1.192a1.622 1.622 0 0 0-1.192-.49c-.467 0-.865.163-1.192.49-.327.328-.49.725-.49 1.192 0 .468.163.865.49 1.192.327.327.725.491 1.192.491Zm-7.852 0c.467 0 .864-.164 1.192-.49.327-.328.49-.725.49-1.193 0-.467-.163-.864-.49-1.192a1.623 1.623 0 0 0-1.192-.49c-.468 0-.865.163-1.192.49-.328.328-.491.725-.491 1.192 0 .468.163.865.49 1.192.328.327.725.491 1.193.491Zm3.926 7.292a5.94 5.94 0 0 0 3.463-1.08 6.152 6.152 0 0 0 2.258-2.847h-1.851a4.599 4.599 0 0 1-1.64 1.641 4.315 4.315 0 0 1-2.23.603 4.315 4.315 0 0 1-2.23-.603 4.6 4.6 0 0 1-1.64-1.64H8.126a6.153 6.153 0 0 0 2.257 2.846 5.94 5.94 0 0 0 3.464 1.08Zm0 5.048c-1.552 0-3.01-.295-4.375-.884a11.328 11.328 0 0 1-3.562-2.398 11.328 11.328 0 0 1-2.398-3.561A10.923 10.923 0 0 1 2.63 14c0-1.552.294-3.01.883-4.375A11.328 11.328 0 0 1 5.91 6.063a11.328 11.328 0 0 1 3.562-2.398 10.923 10.923 0 0 1 4.375-.883c1.552 0 3.01.294 4.375.883a11.33 11.33 0 0 1 3.562 2.398 11.33 11.33 0 0 1 2.397 3.562c.59 1.365.884 2.823.884 4.375s-.295 3.01-.884 4.375a11.329 11.329 0 0 1-2.397 3.561 11.33 11.33 0 0 1-3.562 2.398 10.924 10.924 0 0 1-4.375.884Zm0-2.244c2.505 0 4.627-.87 6.366-2.608 1.739-1.739 2.608-3.86 2.608-6.366s-.87-4.628-2.608-6.366c-1.739-1.74-3.86-2.609-6.366-2.609-2.505 0-4.628.87-6.366 2.609C5.74 9.372 4.872 11.494 4.872 14c0 2.505.87 4.627 2.609 6.366 1.738 1.739 3.86 2.608 6.366 2.608Z'
		/>
	</svg>
);
export default IconSmile;
