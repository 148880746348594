import * as React from 'react';
const CheckCircle = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' viewBox='0 0 18 18' {...props}>
		<path
			fill='#1FA352'
			d='M9 1.125a7.876 7.876 0 1 0 .001 15.751A7.876 7.876 0 0 0 9 1.125Zm3.401 5.303L8.7 11.561a.558.558 0 0 1-.908 0L5.599 8.524a.141.141 0 0 1 .114-.224h.824c.18 0 .35.087.456.234l1.251 1.737 2.763-3.832a.562.562 0 0 1 .456-.234h.824c.114 0 .181.13.114.223Z'
		/>
	</svg>
);
export default CheckCircle;
