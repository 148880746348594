import { bookingType } from 'config/bookingType';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SuperFetch from 'library/helpers/superFetch';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { bookingActions } from './slice';
import { lockersActions } from 'reduxStore/lockers/slice';

dayjs.extend(utc);
dayjs.extend(timezone);

export function* createBooking(action) {
	try {
		const { selectedSegment } = yield select((state) => state.Lockers);
		const { name, personID, deviceID, startTime, endTime } = action.payload;

		//Create the booking
		const createdBooking = yield call(() =>
			SuperFetch.post(
				'/booking',
				{
					name,
					bookingTypeID: bookingType.LOCKER,
					assignedToPerson: personID,
					deviceID,
					startTime,
					endTime,
					timezone: dayjs.tz.guess()
				},
				{}
			)
		);

		if (createdBooking.statusCode === 200) {
			yield put(bookingActions.createBookingSuccess());
			if (selectedSegment === 'Availability') {
				yield put(lockersActions.loadAvailabilityLockers());
			}
		} else {
			yield put(bookingActions.createBookingError());
		}
	} catch (error) {
		yield put(bookingActions.createBookingError());
	}
}

export default function* rootSaga() {
	yield takeEvery([bookingActions.createBooking], createBooking);
}
