import { LinkOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { peopleActions } from 'reduxStore/people/slice';
import { Button, Flex, Modal, Typography } from 'zui/Antd';

const { Title, Text } = Typography;

const EnablePeopleModal = ({ children, visible, setVisible, data }) => {
	const {
		token: { paddingLG, fontSizeSM, marginLG }
	} = theme.useToken();
	const dispatch = useDispatch();

	const { loadingEnableProfile } = useSelector((state) => state.People);

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Button
					onClick={() => setVisible(true)}
					icon={<LinkOutlined />}
					iconPosition='start'
					style={{
						width: '100%'
					}}
				>
					Enable Profile
				</Button>
			)}
			<Modal
				open={visible}
				onCancel={() => handleCancel()}
				maskClosable={false}
				closeIcon={false}
				centered
				destroyOnClose={true}
				footer={[
					<Flex align='center' justify='center' style={{ marginTop: marginLG }} gap={12}>
						<Button onClick={() => handleCancel()} type='default' style={{ width: 130 }}>
							Cancel
						</Button>
						<Button
							onClick={() => dispatch(peopleActions.enablePeople(data))}
							type='primary'
							loading={loadingEnableProfile}
						>
							Enable
						</Button>
					</Flex>
				]}
				width={350}
				styles={{
					content: {
						padding: `43px ${40}px ${paddingLG}px`
					}
				}}
			>
				<Flex vertical align='center' justify='center' gap={12} style={{ padding: `0 ${paddingLG}px` }}>
					<LinkOutlined style={{ fontSize: 80 }} />
					<Flex vertical gap={8}>
						<Title level={5} style={{ margin: 0, lineHeight: '24px', textAlign: 'center' }}>
							Are you sure you want to enable this profile?
						</Title>
						<Text style={{ fontSize: fontSizeSM, lineHeight: '24px', textAlign: 'center' }}>
							This action is prevents the user from accessing the locker system.
						</Text>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};
export default EnablePeopleModal;
