import * as React from 'react';
const MaintenanceBattery = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={26} height={27} fill='none' {...props}>
		<rect width={26} height={26} y={0.5} fill='#059641' rx={13} />
		<path
			stroke='#fff'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.583}
			d='M22.208 14.792v-1.584M6.374 9.25h11.084c.874 0 1.583.709 1.583 1.583v6.334c0 .874-.709 1.583-1.583 1.583H6.374a1.583 1.583 0 0 1-1.583-1.583v-6.334c0-.874.709-1.583 1.583-1.583Z'
		/>
	</svg>
);
export default MaintenanceBattery;
