import * as React from 'react';

const LinkedinIcon = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={22} height={23} fill='none' {...props}>
		<path
			fill='#000'
			fillOpacity={0.45}
			d='M18.747 2.03H2.865c-.84 0-1.521.68-1.521 1.52v15.882c0 .84.681 1.521 1.521 1.521h15.882c.84 0 1.52-.681 1.52-1.52V3.55c0-.84-.68-1.521-1.52-1.521Zm0 17.41a13333.84 13333.84 0 0 1-15.89-.008c.003-10.592.006-15.888.008-15.888 10.593.002 15.889.004 15.889.007-.003 10.592-.005 15.888-.007 15.888ZM4.149 9.123h2.808v9.031H4.15V9.124Zm1.406-1.235a1.629 1.629 0 0 0 .622-3.131 1.627 1.627 0 1 0-.622 3.13Zm5.968 5.798c0-1.178.224-2.319 1.684-2.319 1.438 0 1.46 1.346 1.46 2.394v4.393h2.805v-4.953c0-2.432-.525-4.303-3.366-4.303-1.365 0-2.28.75-2.657 1.46h-.038V9.123H8.717v9.031h2.806v-4.468Z'
		/>
	</svg>
);
export default LinkedinIcon;
