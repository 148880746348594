import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	selectedSegment: '',
	loading: false,
	error: false,
	message: ''
};

const lockersSlice = createSlice({
	name: 'Lockers',
	initialState,
	reducers: {
		loadAvailabilityLockers(state) {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadAvailabilityLockersSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadAvailabilityLockersError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' availability.";
		},
		loadLockersUsage(state) {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadLockersUsageSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadLockersUsageError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' usage.";
		},
		loadLockersBattery(state) {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadLockersBatterySuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadLockersBatteryError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' battery.";
		},
		loadLockersSize(state) {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadLockersSizeSuccess(state, action) {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadLockersSizeError(state) {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' size.";
		},
		loadMaintenanceLockers: (state) => {
			state.loading = true;
			state.error = false;
			state.message = '';
		},
		loadMaintenanceLockersSuccess: (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.error = false;
			state.message = '';
		},
		loadMaintenanceLockersError: (state) => {
			state.loading = false;
			state.error = true;
			state.message = "There was an error loading the lockers' maintenance.";
		},
		setSelectedSegment(state, action) {
			state.selectedSegment = action.payload;
		}
	}
});

export const lockersActions = lockersSlice.actions;

export default lockersSlice.reducer;
