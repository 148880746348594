import * as React from 'react';

const LogoutOutlined = (props) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path
			fill='#000'
			fillOpacity={0.45}
			d='M11.457 1.09a.143.143 0 0 0-.207.13v1.146c0 .098.052.189.134.243.403.253.775.551 1.11.889a6.355 6.355 0 0 1 1.861 4.5 6.345 6.345 0 0 1-1.86 4.5 6.305 6.305 0 0 1-2.022 1.364c-.782.332-1.616.5-2.473.5a6.299 6.299 0 0 1-2.473-.5 6.305 6.305 0 0 1-2.022-1.364 6.34 6.34 0 0 1-1.86-4.5 6.346 6.346 0 0 1 1.86-4.5c.34-.34.711-.636 1.111-.89a.285.285 0 0 0 .134-.242V1.218a.144.144 0 0 0-.207-.13A7.726 7.726 0 0 0 .286 7.902c-.05 4.265 3.437 7.808 7.7 7.816 4.268.01 7.728-3.451 7.728-7.72a7.72 7.72 0 0 0-4.257-6.906ZM7.43 8.86H8.57a.143.143 0 0 0 .143-.142V.432a.143.143 0 0 0-.143-.143H7.43a.143.143 0 0 0-.143.143v8.286c0 .078.064.142.143.142Z'
		/>
	</svg>
);
export default LogoutOutlined;
