import { storage } from 'library/firebase/firebase';

export const getImageUrlFromStorage = async (urlInDatabase) => {
	try {
		const url = await storage.ref().child(urlInDatabase).getDownloadURL();
		return url;
	} catch (err) {
		return null;
	}
};
