import SuperFetch from 'library/helpers/superFetch';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { logsActions } from './slice';
import { lockersActions } from 'reduxStore/lockers/slice';
import { metricsActions } from 'reduxStore/metrics/slice';

export function* loadLockerMaintenanceLogs() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/maintenanceLogs', {}, {}));

		if (lockers.statusCode === 200) {
			yield put(logsActions.loadMaintenanceLockersLogsSuccess(lockers.data));
		} else {
			yield put(logsActions.loadMaintenanceLockersLogsError());
		}
	} catch (error) {
		yield put(logsActions.loadMaintenanceLockersLogsError());
	}
}

export function* upsertMaintenanceLocker(action) {
	try {
		const lockers = yield call(() => SuperFetch.patch('/lockers/maintenance', action.payload, {}));
		const { selectedSegment } = yield select((state) => state.Lockers);

		if (lockers.statusCode === 200) {
			yield put(logsActions.upsertMaintenanceLockerLogsSuccess(lockers.data[0]));
			if (selectedSegment === 'Maintenance') {
				yield put(lockersActions.loadMaintenanceLockers());
			}
			yield put(metricsActions.loadLockerMetrics());
		} else {
			yield put(logsActions.upsertMaintenanceLockerLogsError());
		}
	} catch (error) {
		yield put(logsActions.upsertMaintenanceLockerLogsError());
	}
}

export function* deleteMaintenanceLockerLogs(action) {
	try {
		const { selectedSegment } = yield select((state) => state.Lockers);

		const deleteMaintenanceLocker = yield call(() =>
			SuperFetch.delete(`/lockers/maintenance/${action.payload}`, {}, {})
		);

		if (deleteMaintenanceLocker.statusCode === 200) {
			yield put(logsActions.deleteMaintenanceLockerLogsSuccess(action.payload));
			if (selectedSegment === 'Maintenance') {
				yield put(lockersActions.loadMaintenanceLockers());
			}
			yield put(metricsActions.loadLockerMetrics());
		} else {
			yield put(logsActions.deleteMaintenanceLockerLogsError());
		}
	} catch (error) {
		yield put(logsActions.deleteMaintenanceLockerLogsError());
	}
}

export default function* rootSaga() {
	yield takeEvery(logsActions.loadMaintenanceLockersLogs, loadLockerMaintenanceLogs);
	yield takeEvery(logsActions.upsertMaintenanceLockerLogs, upsertMaintenanceLocker);
	yield takeEvery(logsActions.deleteMaintenanceLockerLogs, deleteMaintenanceLockerLogs);
}
