import { DownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Button, Dropdown, Space } from 'zui/Antd';
import RemoteLockButton from './RemoteLockButton';
import RemoteUnlockButton from './RemoteUnlockButton';

const RemoteActionsButton = ({ data, onIndividualDeviceModal = true }) => {
	const [remoteLockVisible, setRemoteLockVisible] = useState(false);

	const items = [
		{
			key: '1',
			label: <RemoteUnlockButton data={data} />
		},
		{
			key: '2',
			label: <RemoteLockButton data={data} visible={remoteLockVisible} setVisible={setRemoteLockVisible} />
		}
	];
	return (
		<React.Fragment>
			<Dropdown
				menu={{
					items
				}}
			>
				{onIndividualDeviceModal ? (
					<Button onClick={(e) => e.preventDefault()}>
						Lock Actions <DownOutlined />
					</Button>
				) : (
					<Space>
						Lock Actions <i className='material-icons-outlined'>expand_more</i>
					</Space>
				)}
			</Dropdown>
		</React.Fragment>
	);
};

export default RemoteActionsButton;
