import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	lockerMetrics: [],
	peopleMetrics: [],
	recentActivity: [],
	isLoadingLockerMetrics: false,
	isLoadingPeopleMetrics: false,
	isLoadingRecentActivity: false,
	error: {
		error: false,
		message: ''
	}
};

const metricsSlice = createSlice({
	name: 'metrics',
	initialState,
	reducers: {
		loadRecentActivity(state) {
			state.isLoadingRecentActivity = true;
			state.recentActivity = [];
		},
		loadRecentActivitySuccess(state, action) {
			state.recentActivity = action.payload;
			state.isLoadingRecentActivity = false;
		},
		loadRecentActivityError(state) {
			state.isLoadingRecentActivity = false;
			state.error = { error: true, message: 'Failed to load recent activity.' };
		},
		loadLockerPeopleMetrics(state) {
			state.isLoadingPeopleMetrics = true;
			state.peopleMetrics = [];
		},
		loadLockerPeopleMetricsSuccess(state, action) {
			state.peopleMetrics = action.payload;
			state.isLoadingPeopleMetrics = false;
		},
		loadLockerPeopleMetricsError(state) {
			state.isLoadingPeopleMetrics = false;
			state.error = { error: true, message: 'Failed to load locker people metrics.' };
		},
		loadLockerMetrics(state) {
			state.isLoadingLockerMetrics = true;
			state.lockerMetrics = [];
		},
		loadLockerMetricsSuccess(state, action) {
			state.lockerMetrics = action.payload;
			state.isLoadingLockerMetrics = false;
		},
		loadLockerMetricsError(state) {
			state.isLoadingLockerMetrics = false;
			state.error = { error: true, message: 'Failed to load locker metrics.' };
		}
	}
});

export const metricsActions = metricsSlice.actions;

export default metricsSlice.reducer;
