import superFetch from 'library/helpers/superFetch';
import useManagePermittedRoles from 'library/hooks/useManagePermittedRoles';
import React, { useState } from 'react';
import { Notification } from 'zComponents/atoms';
import { Button, Modal, Tooltip } from 'zui/Antd';

const RemoteUnlockButton = ({ children, data }) => {
	// basic information for this modal to be used in the form.
	const { deviceID, name } = data;
	const [visible, setVisible] = useState(false);
	const [unlockLoading, setUnlockLoading] = useState(false);

	const handleCancel = () => {
		console.log('handleCancel', visible);
		setVisible(false);
		setUnlockLoading(false);
	};

	const handleUnlock = async () => {
		if (!deviceID) return;
		setUnlockLoading(true);

		const body = {
			actionID: 1,
			deviceIDs: [deviceID],
			platform: 'Dashboard',
			method: 'Remote'
		};

		const res = await superFetch.post(`/devices/action`, body);
		if (res.statusCode === 200) {
			Notification('success', `${name} has been unlocked successfully.`);
			handleCancel();
		} else {
			console.log(res);
			setUnlockLoading(false);
			Notification('error', res?.data[0].errmsg || res?.data[0].msg);
		}
	};

	// Permission check for remote unlock button.
	const isPermittedRemoteUnlock = useManagePermittedRoles('aCRemoteUnlock', {
		orgID: [data.orgID],
		siteID: [],
		floorID: data?.location?.length > 0 ? data?.location.map((location) => location.floorID) : []
	});

	return (
		<div>
			<Tooltip
				title={
					!isPermittedRemoteUnlock
						? "You don't have permission"
						: 'Remotely Unlock your Device from Anywhere with Internet'
				}
			>
				{children ? (
					children
				) : (
					<>
						<Button
							disabled={!isPermittedRemoteUnlock}
							size={'small'}
							onClick={() => {
								setVisible(true);
							}}
							style={{ width: '100%' }}
						>
							Remote Unlock
						</Button>
					</>
				)}
			</Tooltip>
			<Modal
				title='Remote Unlock Door'
				// visible={visible}
				open={visible}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				footer={[
					<Button key='back' onClick={handleCancel}>
						Cancel
					</Button>,
					<Button
						onClick={() => handleUnlock()}
						key='submit'
						htmlType='submit'
						type='primary'
						loading={unlockLoading}
					>
						Unlock
					</Button>
				]}
			>
				<div style={{ textAlign: 'center' }}>
					Are you sure you want to Unlock <strong>{name ? name : ''}</strong>?
				</div>
			</Modal>
		</div>
	);
};

export default RemoteUnlockButton;
