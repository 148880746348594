import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Image, Input, Row, Segmented, Select, theme } from 'antd';
import { maintenanceType } from 'config/maintenanceType';
import dayjs from 'dayjs';
import { removeEmptyOrNull } from 'library/helpers/utility';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logsActions } from 'reduxStore/lockerLogs/slice';
import { typesActions } from 'reduxStore/types/slice';
import { Loader } from 'zComponents/atoms';
import { Flex, Steps, Typography } from 'zui/Antd';

// Image imports
import MaintenanceBattery from 'assets/images/svg/MaintenanceBattery';
import MaintenanceCard from 'assets/images/svg/MaintenanceCard';
import MaintenanceConfiguration from 'assets/images/svg/MaintenanceConfiguration';
import { appActions } from 'reduxStore/app/slice';
const Mechanics = require('../../../../Assets/images/custom/mechanics.png');

const { TextArea } = Input;
const { Title, Text } = Typography;
const { Step } = Steps;

const MaintenanceDeviceDrawer = () => {
	const { openDrawer } = useSelector((state) => state.App);
	const { data } = useSelector((state) => state.Lockers);

	const dispatch = useDispatch();
	const { maintenanceTypes } = useSelector((state) => state.Types);
	const { lockersMaintenanceLogs, loading } = useSelector((state) => state.Logs);

	const [categories, setCategories] = useState([]);
	const [devices, setDevices] = useState([]);
	const [filteredDevices, setFilteredDevices] = useState([]);
	const [activeTab, setActiveTab] = useState('Add Maintenance');
	const [maintenanceIssue, setMaintenanceIssue] = useState(null);

	const [form] = Form.useForm(); // Ant Design form instance

	const {
		token: { fontWeightStrong, colorIcon, colorTextDescription, fontSizeHeading3, marginMD, marginXXL, marginSM }
	} = theme.useToken();

	useEffect(() => {
		if (openDrawer) {
			dispatch(typesActions.loadMaintenanceTypes());
			dispatch(logsActions.loadMaintenanceLockersLogs());
		}
	}, [openDrawer, dispatch]);

	useEffect(() => {
		if (data && data.length > 0) {
			// Set category options and collect all devices
			const categoryOptions = data.map((category) => ({
				value: category.categoryID,
				label: category.categoryName
			}));

			const allDevices = data.flatMap((category) =>
				category.devices.map((device) => ({
					value: device.deviceID,
					label: device.name,
					categoryID: category.categoryID // Reference to the category
				}))
			);

			setCategories(categoryOptions);
			setDevices(allDevices);
			setFilteredDevices(allDevices); // Initially all devices
		}
	}, [data]);

	const handleCategorySelect = (category) => {
		// Filter devices by the selected category
		const filtered = devices.filter((device) => device.categoryID === category.value);

		setFilteredDevices(filtered);

		// Reset the locker number field when category changes
		form.setFieldsValue({ assetID: null });
	};

	const handleFinish = (values) => {
		//get data to insert into the maintenance table
		const data = removeEmptyOrNull(values);
		delete data.categoryID;

		// Dispatch action to upsert maintenance record
		dispatch(logsActions.upsertMaintenanceLockerLogs(data));

		//Change Active Tab
		setActiveTab('Maintenance Log');

		// Reset form fields
		form.resetFields();
	};

	const handleCloseDrawer = () => {
		form.resetFields();
		dispatch(appActions.collapseOpenDrawer());
	};

	const handleDeleteMaintenanceLockersLogs = (maintenanceID) => {
		dispatch(logsActions.deleteMaintenanceLockerLogs(maintenanceID));
	};

	const getIcon = (status) => {
		switch (status) {
			case 'Battery Issues':
				return <MaintenanceBattery />;
			case 'Card Reader Fail':
				return <MaintenanceCard />;
			default:
				return <MaintenanceConfiguration />;
		}
	};

	return (
		<Drawer
			placement='right'
			open={openDrawer}
			width={410}
			mask={false}
			styles={{
				header: { border: 0 },
				body: { padding: 0 }
			}}
			closeIcon={false}
		>
			<Row justify='end'>
				<Button
					onClick={handleCloseDrawer}
					type='text'
					icon={<CloseOutlined style={{ fontSize: fontSizeHeading3, color: colorIcon }} />}
					style={{ position: 'relative', margin: marginMD }}
				/>
			</Row>

			<div style={{ padding: `0px ${marginXXL}px` }}>
				<Row justify='center'>
					<Col span={24}>
						<Segmented
							options={['Add Maintenance', 'Maintenance Log']}
							value={activeTab}
							onChange={setActiveTab}
						/>
					</Col>

					<Col
						span={24}
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: activeTab === 'Add Maintenance' ? 'center' : 'start',
							justifyContent: 'center',
							margin: `${marginXXL}px ${marginMD}px 22px`
						}}
					>
						{activeTab === 'Add Maintenance' ? (
							<>
								<Image
									src={Mechanics}
									style={{
										width: 122,
										height: 122,
										objectFit: 'contain',
										marginBottom: marginSM
									}}
									preview={false}
								/>
								<Title level={4} style={{ textAlign: 'center', margin: 0 }}>
									Add Maintenance
								</Title>
							</>
						) : (
							<Title level={4} style={{ margin: 0 }}>
								Maintenance Log
							</Title>
						)}
					</Col>
				</Row>

				{activeTab === 'Add Maintenance' ? (
					<Form form={form} layout='vertical' onFinish={handleFinish}>
						<Form.Item
							label='Locker Area'
							name='categoryID'
							required={false}
							rules={[{ required: true, message: 'Please select the Locker Area' }]}
							style={{
								width: '70%',
								fontWeight: fontWeightStrong
							}}
						>
							<Select
								style={{
									fontWeight: 400
								}}
								placeholder='Select Locker Category'
								options={categories}
								onChange={(e, value) => handleCategorySelect(value)}
							/>
						</Form.Item>

						<Form.Item
							label='Locker Number'
							name='assetID'
							required={false}
							rules={[{ required: true, message: 'Please select the Locker Number' }]}
							style={{
								width: '70%',
								fontWeight: fontWeightStrong
							}}
						>
							<Select
								placeholder='Select Locker Number'
								options={filteredDevices}
								style={{
									fontWeight: 400
								}}
							/>
						</Form.Item>

						<Form.Item
							label='Maintenance Issue'
							name='maintenanceTypeID'
							required={false}
							rules={[{ required: true, message: 'Please select an issue' }]}
							style={{
								fontWeight: fontWeightStrong
							}}
						>
							<Select
								placeholder='Select'
								style={{
									fontWeight: 400
								}}
								options={maintenanceTypes.map((type) => ({
									value: type.maintenanceTypeID,
									label: type.userFriendlyName
								}))}
								onChange={(e, value) => setMaintenanceIssue(value.label)}
							/>
						</Form.Item>

						{maintenanceIssue === maintenanceType.OTHER && (
							<Form.Item name='note'>
								<TextArea rows={4} placeholder='Additional comments...' />
							</Form.Item>
						)}

						<Row justify='center'>
							<Form.Item>
								<Button type='default' style={{ marginRight: 8 }} onClick={handleCloseDrawer}>
									Locker Overview
								</Button>
								<Button type='primary' htmlType='submit'>
									Submit
								</Button>
							</Form.Item>
						</Row>
					</Form>
				) : loading ? (
					<Row justify={'center'}>
						<Loader position='relative' />
					</Row>
				) : lockersMaintenanceLogs.length > 0 ? (
					<Steps
						size='small'
						direction='vertical'
						style={{
							width: '100%' // Ensures Steps take full width
						}}
					>
						{lockersMaintenanceLogs?.map((locker) => (
							<Step
								key={locker.maintenanceID}
								icon={getIcon(locker.userFriendlyName)}
								title={
									<Flex justify='space-between' style={{ width: 250 }}>
										<Title level={5} style={{ margin: 0, width: 200 }}>
											{locker.categoryName} - {locker.deviceName}
										</Title>

										<Button
											style={{ position: 'absolute', right: 0, top: -5 }}
											type='text'
											icon={<DeleteOutlined />}
											onClick={() => handleDeleteMaintenanceLockersLogs(locker.maintenanceID)}
										/>
									</Flex>
								}
								description={
									<Text
										style={{
											color: colorTextDescription
										}}
									>
										{dayjs(locker.maintenanceCreatedDate).format('D MMMM') || 'N/A'} -{' '}
										{locker.userFriendlyName === 'Other' ? locker.note : locker.userFriendlyName}
									</Text>
								}
								style={{
									marginBottom: 16
								}}
							/>
						))}
					</Steps>
				) : (
					<Text>No maintenance logs found </Text>
				)}
			</div>
		</Drawer>
	);
};

export default MaintenanceDeviceDrawer;
