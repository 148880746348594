import { call, put, takeEvery } from 'redux-saga/effects';
import { metricsActions } from './slice';
import SuperFetch from 'library/helpers/superFetch';

export function* loadRecentActivities() {
	try {
		const recentActivities = yield call(() => SuperFetch.get('/lockers/recentActivities', {}));
		if (recentActivities.statusCode === 200) {
			yield put(metricsActions.loadRecentActivitySuccess(recentActivities.data));
		} else {
			yield put(metricsActions.loadRecentActivityError());
		}
	} catch (error) {
		yield put(metricsActions.loadRecentActivityError());
	}
}

export function* loadLockerPeopleMetrics() {
	try {
		const peopleMetrics = yield call(() => SuperFetch.get('/lockers/people/metrics', {}));

		if (peopleMetrics.statusCode === 200) {
			yield put(metricsActions.loadLockerPeopleMetricsSuccess(peopleMetrics.data));
		} else {
			yield put(metricsActions.loadLockerPeopleMetricsError('Failed to load locker people metrics.'));
		}
	} catch (error) {
		yield put(metricsActions.loadLockerPeopleMetricsError('Failed to load locker people metrics.'));
	}
}

export function* loadLockerMetrics() {
	try {
		const lockers = yield call(() => SuperFetch.get('/lockers/metrics', {}, {}));
		if (lockers.statusCode === 200) {
			yield put(metricsActions.loadLockerMetricsSuccess(lockers.data));
		} else {
			yield put(metricsActions.loadLockerMetricsError());
		}
	} catch (error) {
		yield put(metricsActions.loadLockerMetricsError());
	}
}

export default function* rootSaga() {
	yield takeEvery(metricsActions.loadRecentActivity, loadRecentActivities);
	yield takeEvery(metricsActions.loadLockerPeopleMetrics, loadLockerPeopleMetrics);
	yield takeEvery(metricsActions.loadLockerMetrics, loadLockerMetrics);
}
