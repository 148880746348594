import { IndividualDeviceInformationModal } from 'zComponents/organisms';
// import { IndividualAssetGroupModal } from '@Z/components/molecules';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

/**
 * Styled component for the clickable wrapper
 */
const ClickableWrapper = styled.div`
	display: grid;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
`;

/**
 * WithHyperLink is a higher-order component to render the link on the entity names.
 * It converts an existing component into a clickable component with the URL of Zezamii's major entity.
 * - Uses linkGenerator() function to provide the map of the entity.
 */
const WithHyperLink = (WrappedComponent) => {
	const EntityLinkHOC = (props) => {
		const navigate = useNavigate();
		const [deviceModalVisible, setDeviceModalVisible] = useState(false);

		// If the Link type is device then it will open the modal instead of redirecting to the page.
		if (props.type === 'device') {
			return (
				<IndividualDeviceInformationModal
					visible={deviceModalVisible}
					setVisible={setDeviceModalVisible}
					data={{
						deviceID: props.dataID,
						...props.suppData
					}}
				>
					<ClickableWrapper onClick={() => setDeviceModalVisible(true)}>
						<WrappedComponent {...props} />
					</ClickableWrapper>
				</IndividualDeviceInformationModal>
			);
		} else if (props.type === 'assetGroup') {
			// return (
			// 	<IndividualAssetGroupModal
			// 		data={{
			// 			groupID: props.dataID,
			// 			...props.suppData
			// 		}}
			// 		visible={groupModalVisible}
			// 		setVisible={setGroupModalVisible}
			// 	>
			// 		<ClickableWrapper onClick={() => setGroupModalVisible(true)}>
			// 			<WrappedComponent {...props} />
			// 		</ClickableWrapper>
			// 	</IndividualAssetGroupModal>
			// );
		} else {
			// Default case for non-modal navigation
			return (
				<ClickableWrapper
					onClick={() => {
						if (props.type) {
							const linkData = linkGenerator(props.dataID)[props.type];
							navigate(linkData.pathname, { state: linkData.state });
						}
					}}
				>
					<WrappedComponent {...props} />
				</ClickableWrapper>
			);
		}
	};

	// Prop types definition
	EntityLinkHOC.propTypes = {
		dataID: PropTypes.number,
		type: PropTypes.string.isRequired,
		suppData: PropTypes.object
	};

	return EntityLinkHOC;
};

export default WithHyperLink;

// Updated link generator for navigation in the HOC
const linkGenerator = (dataID) => ({
	workspace: {
		pathname: `/dashboard/organisations/organisation`,
		state: { orgID: dataID }
	},
	site: {
		pathname: `/dashboard/sites/site`,
		state: { siteID: dataID }
	},
	floor: {
		pathname: `/dashboard/floors/floor`,
		state: { floorID: dataID }
	},
	person: {
		pathname: `/dashboard/people/person`,
		state: { personID: dataID }
	},
	team: {
		pathname: `/dashboard/teams/team`,
		state: { teamID: dataID }
	},
	home: {
		pathname: `/dashboard/`
	},
	sites: {
		pathname: `/dashboard/sites`
	},
	floors: {
		pathname: `/dashboard/floors`
	},
	people: {
		pathname: `/dashboard/people`
	},
	teams: {
		pathname: `/dashboard/teams`
	},
	deviceCategory: {
		pathname: `/dashboard/devices`,
		state: { categoryID: dataID }
	},
	settings: {
		pathname: `/dashboard/settings`
	}
});

export { linkGenerator };
