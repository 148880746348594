import { UnlockOutlined } from '@ant-design/icons';
import { theme } from 'antd';
import SuperFetch from 'library/helpers/superFetch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Notification } from 'zComponents/atoms';
import { Button, Flex, Form, Input, Modal, Segmented, Select, Typography } from 'zui/Antd';

const { Title, Text } = Typography;

const SEGMENTED = ['Specific Locker', 'Locker Area'];
const ACTION_UNLOCK = 1;

const UnlockLockerModal = ({ children, visible, setVisible }) => {
	// Hooks
	const {
		token: {
			fontSizeSM,
			fontWeightStrong,
			colorText,
			marginLG,
			paddingXL,
			marginXL,
			paddingXXS,
			paddingXS,
			marginSM
		}
	} = theme.useToken();

	const { lockerMetrics: data } = useSelector((state) => state.Metrics);

	// Local State
	const [categories, setCategories] = useState([]);
	const [devices, setDevices] = useState([]);
	const [filteredDevices, setFilteredDevices] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(null);
	const [activeTab, setActiveTab] = useState(SEGMENTED[0]);

	const [form] = Form.useForm(); // Ant Design form instance

	useEffect(() => {
		if (data && data.length > 0) {
			// Set category options and collect all devices
			const categoryOptions = data.map((category) => ({
				value: category.categoryID,
				label: category.categoryName
			}));

			const allDevices = data.flatMap((category) =>
				category.devices.map((device) => ({
					value: device.deviceID,
					label: device.name,
					categoryID: category.categoryID // Reference to the category
				}))
			);

			setCategories(categoryOptions);
			setDevices(allDevices);
			setFilteredDevices(allDevices); // Initially all devices
		}
	}, [data]);

	// Handle Segment Change
	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	const handleCategorySelect = (category) => {
		// Filter devices by the selected category
		const filtered = devices.filter((device) => device.categoryID === category.value);

		setFilteredDevices(filtered);
		setSelectedCategory(category.label);

		// Reset the locker number field when category changes
		form.setFieldsValue({ assetID: null });
	};

	const handleCloseDrawer = () => {
		setVisible(false);
		form.resetFields();
	};

	const handleUnlock = async ({ assetID, categoryID, confirmLockerArea }) => {
		try {
			setLoading(true);
			let res;
			if (activeTab === SEGMENTED[0]) {
				// Unlock a specific locker
				res = await SuperFetch.post(`/devices/action`, { deviceIDs: [assetID], actionID: ACTION_UNLOCK }, {});
			} else {
				if (selectedCategory === confirmLockerArea) {
					// Unlock all lockers in the selected category
					res = await SuperFetch.post(
						`/devices/action`,
						{ categoryIDs: [categoryID], actionID: ACTION_UNLOCK },
						{}
					);
				} else {
					// Show error message
					Notification(
						'error',
						'The locker area name does not match the selected locker area. Please confirm the locker area name.'
					);
					setLoading(false);
				}
			}

			if (res.statusCode === 200) {
				setLoading(false);
				Notification('success', res.info);
				handleCloseDrawer();
			} else {
				Notification('error', res.data[0].errmsg);
				setLoading(false);
			}
		} catch (error) {
			Notification(
				'error',
				activeTab === SEGMENTED[0] ? 'The lock cannot be unlocked' : 'The locks cannot be unlocked'
			);
			setLoading(false);
		}
	};

	return (
		<>
			{children ? (
				children
			) : (
				<Button
					icon={<UnlockOutlined />}
					iconPosition='start'
					style={{
						margin: `${marginSM}px ${marginSM}px 0px`
					}}
					onClick={() => {
						setVisible(true);
					}}
				>
					Remote Unlock
				</Button>
			)}
			<Modal
				open={visible}
				onCancel={() => handleCloseDrawer()}
				onClose={() => handleCloseDrawer()}
				centered
				footer={null}
				width={425}
				height={416}
				styles={{
					body: {
						padding: `${paddingXXS}px ${paddingXS}px`
					}
				}}
			>
				{/* Title Section */}
				<Title level={4} style={{ margin: 0 }}>
					Remote Unlock
				</Title>
				<Text
					style={{
						display: 'block',
						marginBottom: marginLG,
						lineHeight: '20px',
						fontSize: fontSizeSM,
						color: colorText
					}}
				>
					Remotely unlock individual lockers or all lockers in an area to assist users experiencing issues.
				</Text>

				{/* Tabs for Specific Locker / Locker Area */}
				<Segmented
					value={activeTab}
					onChange={handleTabChange}
					options={SEGMENTED}
					style={{
						padding: `2px ${paddingXL}px`
					}}
					block
				/>

				{/* Form Section */}
				<Form
					id
					form={form}
					layout='vertical'
					style={{ marginTop: 16 }}
					onFinish={(values) => handleUnlock(values)}
				>
					<Form.Item
						label='Locker Area'
						name='categoryID'
						required={false}
						rules={[{ required: true, message: 'Please select the Locker Area' }]}
						style={{
							fontWeight: fontWeightStrong
						}}
					>
						<Select
							style={{
								fontWeight: 400
							}}
							placeholder='Select Locker Category'
							options={categories}
							onChange={(e, value) => handleCategorySelect(value)}
						/>
					</Form.Item>

					{activeTab === SEGMENTED[0] ? (
						<Form.Item
							label='Locker Number'
							name='assetID'
							required={false}
							rules={[{ required: true, message: 'Please select the Locker Number' }]}
							style={{
								fontWeight: fontWeightStrong
							}}
						>
							<Select
								placeholder='Select Locker Number'
								options={filteredDevices}
								style={{
									fontWeight: 400
								}}
							/>
						</Form.Item>
					) : (
						<Form.Item
							label='Confirm Locker Area'
							name='confirmLockerArea'
							required={false}
							style={{
								fontWeight: fontWeightStrong
							}}
							rules={[{ required: true, message: 'Please confirm the Locker Area by typing the name' }]}
						>
							<Input
								style={{
									fontWeight: 400
								}}
								placeholder='Type the Locker Area name'
							/>
						</Form.Item>
					)}

					{/* Footer Buttons */}
					<Flex style={{ marginTop: marginXL }} justify='flex-end' gap={20}>
						<Button onClick={() => handleCloseDrawer()}>Cancel</Button>
						<Button type='primary' htmlType='submit' loading={loading} disabled={loading}>
							Unlock
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export default UnlockLockerModal;
