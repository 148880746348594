import * as React from 'react';
const IconNeutral = ({ fill, ...props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width={28} height={28} fill='none' {...props}>
		<path
			fill={fill || '#5F6368'}
			d='M18.08 12.878c.467 0 .865-.164 1.192-.49.327-.328.49-.725.49-1.193 0-.467-.163-.864-.49-1.192a1.623 1.623 0 0 0-1.192-.49c-.468 0-.865.163-1.192.49-.327.328-.49.725-.49 1.192 0 .468.163.865.49 1.192.327.327.724.491 1.192.491Zm-7.853 0c.468 0 .865-.164 1.192-.49.327-.328.49-.725.49-1.193 0-.467-.163-.864-.49-1.192a1.623 1.623 0 0 0-1.192-.49c-.467 0-.864.163-1.192.49-.327.328-.49.725-.49 1.192 0 .468.163.865.49 1.192.328.327.725.491 1.192.491Zm.561 5.048h6.73v-1.683h-6.73v1.683Zm3.365 7.292c-1.551 0-3.01-.295-4.375-.884a11.328 11.328 0 0 1-3.561-2.398 11.328 11.328 0 0 1-2.398-3.561A10.923 10.923 0 0 1 2.936 14c0-1.552.294-3.01.883-4.375a11.328 11.328 0 0 1 2.398-3.562 11.329 11.329 0 0 1 3.561-2.398 10.923 10.923 0 0 1 4.375-.883c1.552 0 3.01.294 4.376.883a11.329 11.329 0 0 1 3.561 2.398 11.328 11.328 0 0 1 2.398 3.562c.589 1.365.883 2.823.883 4.375s-.294 3.01-.883 4.375a11.327 11.327 0 0 1-2.398 3.561 11.329 11.329 0 0 1-3.561 2.398 10.924 10.924 0 0 1-4.376.884Zm0-2.244c2.506 0 4.628-.87 6.367-2.608 1.739-1.739 2.608-3.86 2.608-6.366s-.87-4.628-2.608-6.366c-1.74-1.74-3.861-2.609-6.367-2.609-2.505 0-4.627.87-6.366 2.609C6.05 9.372 5.18 11.494 5.18 14c0 2.505.87 4.627 2.608 6.366 1.74 1.739 3.861 2.608 6.366 2.608Z'
		/>
	</svg>
);
export default IconNeutral;
